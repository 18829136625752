<template>
  <div>
    <v-card>
      <v-card-title>Limpeza de eventos da camera</v-card-title>
      <v-card-text>
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  :items="this.$store.getters.getCamerasSelect"
                  label="Câmeras"
                  v-model="relatorio.cameraId"
                  hide-details
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Data Inicial"
                  v-model="relatorio.start"
                  type="datetime-local"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Data Final"
                  v-model="relatorio.end"
                  type="datetime-local"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="clearPreview"> Buscar </v-btn>
          </v-card-actions>
        </v-card>
        <v-card outlined class="mt-2">
          <v-card-text>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>Quantidade de eventos</v-card-title>
                  <v-card-text>
                    <p class="font-weight-black">
                      {{ clearPreviewData.length }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card>
                  <v-card-title>Espaço Liberado</v-card-title>
                  <v-card-text>
                    <p class="font-weight-black">
                      {{ humanReadableSize(clearPreviewData.space) }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-switch v-model="showData" label="Mostrar Dados"></v-switch>
              </v-col>
            </v-row>
            <v-row v-if="showData">
              <v-col>
                <v-btn @click="showRaw = !showRaw">Mostrar raw</v-btn>
              </v-col>
            </v-row>
            <div v-if="showData">
              <pre v-if="showRaw">{{ showPreviewData(clearPreviewData) }}</pre>
              <div
                v-else
                v-html="jsonViewer(clearPreviewData, true)"
                class="viewer"
              ></div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer> </v-spacer>
            <v-btn
              color="error"
              :disabled="!clearPreviewData.cameraId"
              @click="clearData"
              >Executar a limpeza</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { jsonViewer } from "@/Utils/JsonPreview";
export default {
  name: "EventCount",
  components: {},
  data: () => {
    return {
      showData: false,
      loading: false,
      relatorio: {
        cameraId: null,
        start: `${new Date().toISOString().substring(0, 10) + "T00:00"}`,
        end: "",
      },
      clearPreviewData: {},
      showRaw: false,
      jsonViewer: jsonViewer,
      ws: null,
    };
  },
  computed: {
    ...mapGetters([
      "getCamerasSelect",
      "getClienteNameById",
      "getCameraNameById",
    ]),
  },
  methods: {
    clearPreview() {
      this.clearPreviewData = {};
      this.loading = true;
      if (!this.relatorio.cameraId) delete this.relatorio.cameraId;
      delete this.relatorio.diff;
      const params = new URLSearchParams(this.relatorio);
      this.$http
        .get("eventos/clearPreview?" + params)
        .then((response) => {
          this.clearPreviewData = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    clearData() {
      this.$http
        .delete("eventos/clearData", {
          data: {
            ...this.relatorio,
            token: this.clearPreviewData.token,
          },
        })
        .then(() => {
          // this.loading = false;
          // this.clearPreview();
          console.log("limpeza iniciada com sucesso");
          this.$store.dispatch("resetSnack");
          this.$store.dispatch(
            "showSuccessSnack",
            "limpeza iniciada com sucesso"
          );
        })
        .catch(() => {
          this.loading = false;
        });
    },
    showPreviewData() {
      return JSON.stringify(this.clearPreviewData, null, 2);
    },
    humanReadableSize(bytes) {
      if (!bytes) return "";
      if (bytes < 1024) return bytes + " KB";
      else if (bytes < 1024 * 1024) return (bytes / 1024.0).toFixed(4) + " MB";
      else return (bytes / 1024.0 / 1024.0).toFixed(4) + " GB";
    },
  },
  mounted() {
    // pega a data atual e seta no relatorio
    let now = new Date();
    let onlyDate = now.toISOString().substring(0, 11);
    this.relatorio.start = onlyDate + "00:00";
    this.relatorio.end = onlyDate + "23:59";
  },
};
</script>

<style>
.json {
  font-family: "Source Code Pro", monospace;
  font-size: 16px;
}

.json > .json__item {
  display: block;
}

.json__item {
  display: none;
  margin-top: 10px;
  padding-left: 20px;
  user-select: none;
}

.json__item--collapsible {
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.json__item--collapsible::before {
  content: "+";
  position: absolute;
  left: 5px;
}

.json__item--collapsible::after {
  background-color: lightgrey;
  content: "";
  height: 100%;
  left: 9px;
  position: absolute;
  top: 26px;
  width: 1px;
}

.json__item--collapsible:hover > .json__key,
.json__item--collapsible:hover > .json__value {
  text-decoration: underline;
}

.json__toggle {
  display: none;
}

.json__toggle:checked ~ .json__item {
  display: block;
}

.json__key {
  color: darkblue;
  display: inline;
}

.json__key::after {
  content: ": ";
}

.json__value {
  display: inline;
}

.json__value--string {
  color: green;
}

.json__value--number {
  color: blue;
}

.json__value--boolean {
  color: red;
}

.viewer {
  background-color: rgb(142, 142, 142);
}
</style>