<template>
  <div>
    <v-card>
      <v-card-title>Cameras por mês</v-card-title>
      <v-card-text>
        <!-- grafico -->
        <v-row>
          <v-col>
            <v-sparkline
              :labels="labels"
              :value="value"
              color="white"
              line-width="2"
              padding="16"
              label-size="3"
            ></v-sparkline>
          </v-col>
        </v-row>
        <!-- botão exportar -->
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn color="success" text @click="exportExcellSts">
              <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
              Exportar Estatisticas</v-btn
            >
          </v-col>
        </v-row>
        <!-- linha da contafem -->
        <v-row v-for="s in data" :key="s.mounth">
          <v-col cols="2">
            <span>{{ s.mounth }}</span>
          </v-col>
          <v-col>
            <span>{{ s.length }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { camerasSts } from "@/Utils/Excel";
export default {
  name: "CameraMez",
  components: {},
  data: () => {
    return {
      labels: [],
      value: [],
      data: [],
    };
  },
  computed: {},
  methods: {
    exportExcellSts() {
      let c = this.$store.getters.getCamerasFilter;
      c.forEach((item) => {
        item.clientName = this.$store.getters.getClienteNameById(item.clientId);
        item.groupName = this.$store.getters.getGroupNameById(item.groupId);
      });
      camerasSts(c, "Estatisticas de Cameras", "Estatisticas Cameras");
    },
  },
  mounted() {
    let mounths = [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ];
    let cameras = this.$store.getters.getCameras;
    cameras.forEach((e) => {
      let mes = mounths[new Date(e.createdAt).getMonth()];
      let ano = new Date(e.createdAt).getFullYear();
      ano = `${ano}`.substring(2, 4);
      let idx = this.data.findIndex((e) => e.mounth == `${mes}/${ano}`);
      if (idx >= 0) {
        this.data[idx].length++;
        return;
      } else {
        this.data.push({ mounth: `${mes}/${ano}`, length: 1 });
      }
    });
    this.data.forEach((e) => {
      this.labels.push(e.mounth);
      this.value.push(e.length);
    });
  },
};
</script>

<style>
</style>