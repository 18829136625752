<template>
  <div>
    <v-card>
      <v-card-title>Estatísticas de Eventos</v-card-title>
      <v-card-text>
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  :items="this.$store.getters.getCamerasSelect"
                  label="Câmeras"
                  v-model="relatorio.camerasIds"
                  multiple
                  clearable
                  hide-details
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-if="relatorio.camerasIds.length == 1"
                      v-bind="data.attrs"
                      small
                    >
                      {{ data.item.text }}
                    </v-chip>
                    <span v-else-if="data.index === 0">
                      {{ `${relatorio.camerasIds.length} selecionadas` }}
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Data Inicial"
                  v-model="relatorio.start"
                  type="datetime-local"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Data Final"
                  v-model="relatorio.end"
                  type="datetime-local"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="load"> Buscar </v-btn>
          </v-card-actions>
        </v-card>

        <v-data-table
          :headers="headers"
          :items="camerasReport"
          :items-per-page="15"
          :loading="loading"
          class="elevation-1 mt-4"
        >
          <template slot="item.clientId" slot-scope="{ item }">
            {{ getClienteNameById(item.clientId) }}
          </template>
          <template slot="item.filesSize" slot-scope="{ item }">
            {{ `${item.filesSize.toFixed(2)} MB` }}
          </template>
          <template slot="item.event4day" slot-scope="{ item }">
            <v-card>
              <v-card-text>
                <v-row no-gutters>
                  <v-col> Eventos: {{ event4day(item).toFixed(2) }} </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>Imagens: {{ pic4day(item).toFixed(2) }}</v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>Espaço: {{ size4day(item).toFixed(2) }} MB </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { differenceDate } from "@/Utils/dateDiff.js";
export default {
  name: "EventCount",
  components: {},
  data: () => {
    return {
      camerasReport: [],
      loading: false,
      headers: [
        { text: "Id", value: "id" },
        { text: "Nome", value: "name" },
        { text: "Cliente", value: "clientId" },
        { text: "Eventos", value: "events" },
        { text: "Imagens", value: "files" },
        { text: "Tamanho das Imagens", value: "filesSize" },
        { text: "Por dia", value: "event4day" },
      ],
      relatorio: {
        camerasIds: null,
        start: `${new Date().toISOString().substring(0, 10) + "T00:00"}`,
        end: `${new Date().toISOString().substring(0, 10) + "T23:59"}`,
        diff: {},
      },
      dirSize: [],
      camerasSize: 0,
    };
  },
  computed: {
    ...mapGetters([
      "getCamerasSelect",
      "getClienteNameById",
      "getCameraNameById",
    ]),
  },
  methods: {
    load() {
      this.camerasReport = [];
      this.loading = true;
      if (!this.relatorio.camerasIds) delete this.relatorio.camerasIds;
      delete this.relatorio.diff;
      const params = new URLSearchParams(this.relatorio);
      this.$http
        .get("statistics/eventsAndFiles?" + params)
        .then((response) => {
          this.relatorio.diff = differenceDate(
            this.relatorio.start,
            this.relatorio.end
          );
          this.camerasReport = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    loadDirSize() {
      this.$http
        .get("statistics/camDir")
        .then((response) => {
          this.camerasSize = 0;
          this.dirSize = response.data;
          this.dirSize.cameras.forEach((element) => {
            this.camerasSize += parseInt(element.size);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    humanReadableSize(bytes) {
      if (!bytes) return "";
      if (bytes < 1024) return bytes + " KB";
      else if (bytes < 1024 * 1024) return (bytes / 1024.0).toFixed(4) + " MB";
      else return (bytes / 1024.0 / 1024.0).toFixed(4) + " GB";
    },
    event4day(item) {
      let diff = this.relatorio.diff.day;
      return item.events / (diff ? diff : 1);
    },
    pic4day(item) {
      let diff = this.relatorio.diff.day;
      return item.files / (diff ? diff : 1);
    },
    size4day(item) {
      let media = item.filesSize / item.files;
      return media * this.pic4day(item) || 0;
    },
  },
  mounted() {
    // pega a data atual e seta no relatorio
    let now = new Date();
    let onlyDate = now.toISOString().substring(0, 11);
    this.relatorio.start = onlyDate + "00:00";
    this.relatorio.end = onlyDate + "23:59";
  },
};
</script>

<style>
</style>