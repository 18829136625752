// recebe duas datas e retorna a diferença entre elas
exports.differenceDate = (startDate, endDate, returnString = false) => {
  let date = new Date(startDate);
  let date2 = new Date(endDate);
  
  // verificando se as datas são validas
  if (date == "Invalid Date" || date2 == "Invalid Date") return "Invalid Date";
  let diff = date2.getTime() - date.getTime();
  
  let negative = false;
  // colocando a data menor na variavel date
  if (date > date2) {
      let a = date;
      date = date2;
      date2 = a;
      negative = true;
  }
  
  // modelo de retorno
  let model = {
      year: 0,
      month: 0,
      day: 0,
      hour: 0,
      minute: 0,
      second: 0,
      totalHours: 0,
      totalMinutes: 0,
      negative
  };
      
  // total de minutos
  model.totalMinutes =  diff / (1000 * 60);
  // total de hotas
  model.totalHours =  diff / (1000 * 60 * 60);
  
  // operação de segundos
  let sec = date2.getSeconds() - date.getSeconds();
  if (sec >= 0) {
      model.second = sec;
  } else {
      date2.setMinutes(date2.getMinutes() - 1);
      model.second = 60 + sec;
  }
  // operação de minutos
  let min = date2.getMinutes() - date.getMinutes();
  if (min >= 0) {
      model.minute = min;
  } else {
      date2.setHours(date2.getHours() - 1);
      model.minute = 60 + min;
  }
  // operação de horas
  let hour = date2.getHours() - date.getHours();
  if (hour >= 0) {
      model.hour = hour;
  } else {
      date2.setDate(date2.getDate() - 1);
      model.hour = 24 + hour;
  }
  // operação de dias
  let day = date2.getDate() - date.getDate();
  if (day >= 0) {
      model.day = day;
  } else {
      date2.setMonth(date2.getMonth() - 1);
      // pega o ultimo dia do mes e soma com o dia
      model.day = this.getMaxDays(date2.getFullYear(), date2.getMonth() + 1) + day;
  }
  // operação de meses
  let month = date2.getMonth() - date.getMonth();
  if (month >= 0) {
      model.month = month;
  } else {
      date2.setFullYear(date2.getFullYear() - 1);
      model.month = 12 + month;
  }
  // operação de anos
  let year = date2.getFullYear() - date.getFullYear();
  if (year >= 0) {
      model.year = year;
  }
  // retorna a string ou o modelo
  if (returnString) return toString(model);
  return model;
  }
  
  // pega o ultimo dia do mes
  exports.getMaxDays = (year, month) => {
  year = year || new Date().getFullYear();
  month = month || new Date().getMonth() + 1;
  return new Date(year, month, 0).getDate();
  }
  
  // retorna a string
  function toString(model) {
  let string = "";
  if (model.year > 0) string += `${model.year} ano${model.year > 1 ? 's' : ''}, `;
  if (model.month > 0) string += `${model.month} mes${model.month > 1 ? 'es' : ''}, `;
  if (model.day > 0) string += `${model.day} dia${model.day > 1 ? 's' : ''}, `;
  if (model.hour > 0) string += `${model.hour} hora${model.hour > 1 ? 's' : ''}, `;
  if (model.minute > 0) string += `${model.minute} minuto${model.minute > 1 ? 's' : ''}, `;
  if (model.second > 0) string += `${model.second} segundo${model.second > 1 ? 's' : ''}`;
  return string;
  }
    
    // console.log(this.differenceDate(new Date(), new Date(2021, 2, 6, 11, 55, 00), true));