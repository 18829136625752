<template>
  <div>
    <v-card>
      <v-card-title>Camera eventos da camera por dia</v-card-title>
      <v-card-text>
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  :items="this.$store.getters.getCamerasSelect"
                  label="Câmera"
                  v-model="relatorio.cameraId"
                  hint="Selecione uma câmera para ver os eventos"
                  persistent-hint
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Data Inicial"
                  v-model="relatorio.start"
                  type="datetime-local"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Data Final"
                  v-model="relatorio.end"
                  type="datetime-local"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="warning"
              text
              @click="clearPreview"
              :disabled="camerasReport.length == 0"
            >
              Executar Limpeza
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="load"> Buscar </v-btn>
          </v-card-actions>
        </v-card>
        <v-card outlined class="mt-2">
          <v-card-text>
            <v-row>
              <v-col>
                <span>
                  <span class="font-weight-black">
                    Total de eventos do periodo solicitado:
                  </span>
                  {{ eventCount }}
                </span>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="camerasReport"
              :items-per-page="-1"
              :loading="loading"
              class="elevation-1 mt-4"
            >
              <template slot="item.date" slot-scope="{ item }">
                {{ new Date(item.date).toLocaleDateString() }}
              </template>
              <template slot="item.pct" slot-scope="{ item }">
                <v-progress-linear
                  :value="(item.length / eventCount) * 100"
                  height="20"
                  striped
                >
                  <strong
                    >{{
                      ((item.length / eventCount) * 100).toFixed(2)
                    }}%</strong
                  >
                </v-progress-linear>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { differenceDate } from "@/Utils/dateDiff.js";
export default {
  name: "EventCount",
  components: {},
  data: () => {
    return {
      camerasReport: [],
      loading: false,
      headers: [
        { text: "Dia", value: "date" },
        { text: "Contagem", value: "length" },
        {
          text: "Porcentagem dentro do periodo",
          value: "pct",
          sortable: false,
        },
      ],
      relatorio: {
        cameraId: null,
        start: `${new Date().toISOString().substring(0, 10) + "T00:00"}`,
        end: `${new Date().toISOString().substring(0, 10) + "T23:59"}`,
      },
      dirSize: [],
      eventCount: 0,
      clearPreviewData: [],
    };
  },
  computed: {
    ...mapGetters([
      "getCamerasSelect",
      "getClienteNameById",
      "getCameraNameById",
    ]),
  },
  methods: {
    load() {
      this.camerasReport = [];
      this.loading = true;
      if (!this.relatorio.cameraId) delete this.relatorio.cameraId;
      delete this.relatorio.diff;
      const params = new URLSearchParams(this.relatorio);
      this.$http
        .get("statistics/cam4Days?" + params)
        .then((response) => {
          this.eventCount = 0;
          this.relatorio.diff = differenceDate(
            this.relatorio.start,
            this.relatorio.end
          );
          this.camerasReport = response.data;
          this.camerasReport.forEach((element) => {
            this.eventCount += parseInt(element.length);
          });
          console.log(response.data);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    clearPreview() {
      this.clearPreviewData = [];
      this.loading = true;
      if (!this.relatorio.cameraId) delete this.relatorio.cameraId;
      delete this.relatorio.diff;
      const params = new URLSearchParams(this.relatorio);
      this.$http
        .get("eventos/clearPreview?" + params)
        .then((response) => {
          this.clearPreviewData = response.data;
          console.log(response.data);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  mounted() {
    // pega a data atual e seta no relatorio
    let now = new Date();
    let onlyDate = now.toISOString().substring(0, 11);
    this.relatorio.start = onlyDate + "00:00";
    this.relatorio.end = onlyDate + "23:59";
  },
};
</script>

<style>
</style>