var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-2",attrs:{"outlined":"","loading":_vm.loading}},[_c('v-card-title',[_vm._v(" Buscar tamanho do diretorio de imagens "),_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"success","text":""},on:{"click":_vm.exportExcell}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-microsoft-excel")]),_vm._v(" Exportar")],1),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":_vm.loadDirSize}},[_vm._v(" Buscar ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_vm._v(" Tamanho do HD: "+_vm._s(_vm.humanReadableSize(_vm.dirSize.size))+" ")]),_c('v-col',[_vm._v(" Ocupado: "+_vm._s(_vm.humanReadableSize(_vm.dirSize.used))+" ("+_vm._s(_vm.dirSize.pct)+") ")]),_c('v-col',[_vm._v(" Livre: "+_vm._s(_vm.humanReadableSize(_vm.dirSize.available))+" ")]),_c('v-col',[_vm._v(" Cameras: "+_vm._s(_vm.humanReadableSize(_vm.camerasSize))+" ")])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"headers":[
              { text: 'Id Câmera', value: 'cameraId' },
              { text: 'Câmera', value: 'cameraName' },
              { text: 'Tamanho', value: 'size' },
              { text: 'Grupo', value: 'groupName' },
              { text: 'Cliente', value: 'clientName' },
              { text: 'PCT no HD', value: 'pct', width: '200' },
              { text: 'PCT entre cameras', value: 'pct2', width: '200' } ],"items":_vm.dirSize.cameras,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.cameraName",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCameraNameById(item.cameraId))+" ")]}},{key:"item.pct",fn:function(ref){
            var item = ref.item;
return [_c('v-progress-linear',{attrs:{"value":(item.sizeBytes / _vm.dirSize.used) * 100,"height":"20","striped":""}},[_c('strong',[_vm._v(_vm._s(((item.sizeBytes / _vm.dirSize.used) * 100).toFixed(2))+"%")])])]}},{key:"item.pct2",fn:function(ref){
            var item = ref.item;
return [_c('v-progress-linear',{attrs:{"value":(item.sizeBytes / _vm.camerasSize) * 100,"height":"20","striped":""}},[_c('strong',[_vm._v(_vm._s(((item.sizeBytes / _vm.camerasSize) * 100).toFixed(2))+"%")])])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }